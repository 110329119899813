import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/authcontext"
import { Link } from "gatsby"

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const actionCodeSettings = {
    url: "https://readings-askthetarot.netlify.app/authorise",
    handleCodeInApp: false,
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value, actionCodeSettings)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  return (
    <>
      <Card className="w-100 mt-3" style={{ maxWidth: `45rem` }}>
        <Form
          name="forgotpasswordform"
          onSubmit={handleSubmit}
          style={{ background: `transparent`, border: `none`, padding: `0` }}
        >
          <Card.Header
            className="m-3 p-3 text-center"
            style={{ textShadow: `0.25rem 0.25rem #412456` }}
          >
            {" "}
            <h3>Password Reset</h3>
          </Card.Header>
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}

            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>

            <div className="w-100 text-center mt-3 AUTH">
              <Link to="/authorise">Log In</Link>
            </div>
          </Card.Body>
          <Card.Footer className="m-0 p-0">
            <Button disabled={loading} className="w-100" type="submit">
              Reset Password
            </Button>
          </Card.Footer>
        </Form>
      </Card>
      <div className="w-100 text-center mt-2 AUTH">
        <p> No Profile? </p> <Link to="/authorise-new">Create One Here</Link>
      </div>
    </>
  )
}
